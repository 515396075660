import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import Report from "../../pages/report/report";

const Main = () => {
  const location = useLocation();
  let pathUrl = location.search;
  const { state, subject, grade, contentType} = checkUrlInputs(pathUrl);

  return (
    <Routes>
      <Route
        path="/"
        element={<Report state={state} subject={subject} grade={grade} contentType={contentType}/>}
      ></Route>
    </Routes>
  );
};

const validUSAStateCodes = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
const validListOfSubjects = ["Math", "Reading", "Purpose"];
const validListOfGradesSelection = [
  "K-12",
  "K-5",
  "6-12",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const validListOfGradesSelectionPurpose = ["K-5", "K", "1", "2", "3", "4", "5"];
const validListOfGradesSelectionPractice = ["K-2", "3-6", "K-6", "K", "1", "2", "3", "4", "5", "6"]
const validListOfContentTypes = ["Instruction", "Practice"]

const emptyString = "";
const defaultState = "AZ";
const defaultSubject = "Math";
const defaultGrade = "1";
const defaultContentType = "Instruction";

function checkUrlInputs(pathUrl: string) {
  const searchParams = new URLSearchParams(pathUrl);
  let state = searchParams.get("state") || emptyString;
  let subject = searchParams.get("subject") || emptyString;
  let grade = searchParams.get("gradeband") || emptyString;
  let contentType = searchParams.get("contenttype") || emptyString;

  let dirtyUrl = false;
  if (!validUSAStateCodes.includes(state)) {
    state = defaultState;
    dirtyUrl = true;
  }
  if (!validListOfSubjects.includes(subject)) {
    subject = defaultSubject;
    dirtyUrl = true;
  }

  if (subject.toLowerCase() === "purpose") {
    if (!validListOfGradesSelectionPurpose.includes(grade)) {
      grade = defaultGrade;
      dirtyUrl = true;
    }
  } else if (contentType.toLowerCase() === "practice") {
    if (!validListOfGradesSelectionPractice.includes(grade)) {
      grade = defaultGrade;
      dirtyUrl = true;
    }
  }
    else {
    if (!validListOfGradesSelection.includes(grade)) {
      grade = defaultGrade;
      dirtyUrl = true;
    }
  }

  if (!validListOfContentTypes.includes(contentType)) {
    contentType = defaultContentType;
    dirtyUrl = true;
  }

  if (contentType !== defaultContentType && subject !== "Math") {
    contentType = defaultContentType;
    dirtyUrl = true;
  }

  if (dirtyUrl) {
    window.location.href = `?state=${state}&gradeband=${grade}&subject=${subject}&contenttype=${contentType}`;
  }

  return { state, subject, grade, contentType };
}

export default Main;
