import React from "react";
import TableRow from "./tablerow";
import "bootstrap/dist/css/bootstrap.min.css";
import "./reporttable.css";
import useFetchData from "./useFetchData";

const ReportTable = ({ state = "", subject = "", grade = "", contentType = ""}) => {
  let gradeString = "&GradeLevels=";
  if (grade.toLowerCase() === "k") {
    gradeString = gradeString + "0";
  } else if (grade.toLowerCase() === "k-12") {
    gradeString =
      "&GradeLevels=0&GradeLevels=1&GradeLevels=2&GradeLevels=3&GradeLevels=4&GradeLevels=5&GradeLevels=6&GradeLevels=7&GradeLevels=8&GradeLevels=9&GradeLevels=10&GradeLevels=11&GradeLevels=12";
  } else if (grade.toLowerCase() === "k-5") {
    gradeString =
      "&GradeLevels=0&GradeLevels=1&GradeLevels=2&GradeLevels=3&GradeLevels=4&GradeLevels=5";
  } else if (grade.toLowerCase() === "6-12") {
    gradeString =
      "&GradeLevels=6&GradeLevels=7&GradeLevels=8&GradeLevels=9&GradeLevels=10&GradeLevels=11&GradeLevels=12";
  } else if (grade.toLowerCase() === "k-6") {
    gradeString =
      "&GradeLevels=0&GradeLevels=1&GradeLevels=2&GradeLevels=3&GradeLevels=4&GradeLevels=5&GradeLevels=6";
  } else if (grade.toLowerCase() === "k-2") {
    gradeString =
      "&GradeLevels=0&GradeLevels=1&GradeLevels=2";
  } else if (grade.toLowerCase() === "3-6") {
    gradeString =
      "&GradeLevels=3&GradeLevels=4&GradeLevels=5&GradeLevels=6"
  } else {
    gradeString = gradeString + grade;
  }

  const { isLoading, apiData, serverError } = useFetchData(
    state,
    gradeString,
    subject,
    contentType
  );

  if (isLoading) {
    return (
      <div
        data-testid="loading-animation"
        className="d-flex justify-content-center"
      >
        <div
          className="spinner-border"
          style={{
            width: "9rem",
            height: "9rem",
            color: "#150e73",
          }}
          role="status"
        ></div>
      </div>
    );
  }

  if (serverError) {
    return (
      <div className="d-flex justify-content-center">
        <h1>Server Error</h1>
      </div>
    );
  }

  return (
    <>
      <table
        id="StandardsGuideTable"
        data-testid="reportTable"
        className="table table-striped table-bordered table-sm"
      >
        <thead>
          <tr>
            <th scope="col">Grade</th>
            <th scope="col">Standard</th>
            <th scope="col">Lesson Title</th>
            <th scope="col">Lesson Description</th>
          </tr>
        </thead>
        {apiData.length > 0 && <TableRow data={apiData} />}
      </table>
    </>
  );
};

export default ReportTable;
