import React from "react";

const CsvIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18pt"
      height="18pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#150e73"
        stroke="none"
      >
        <path
          d="M1825 4334 c-396 -71 -744 -332 -924 -692 -78 -156 -131 -375 -131
            -545 l0 -88 -32 -11 c-62 -21 -198 -97 -270 -151 -343 -255 -517 -677 -453
            -1100 76 -505 488 -905 995 -967 69 -8 520 -10 1670 -8 l1575 3 95 27 c191 53
            342 142 476 280 309 317 380 774 183 1172 -121 243 -356 442 -609 516 -41 12
            -80 23 -87 25 -8 3 -5 22 10 67 28 86 31 311 4 408 -58 212 -207 394 -402 490
            -124 61 -188 75 -345 75 -152 0 -231 -18 -350 -81 -35 -19 -68 -34 -71 -34 -4
            0 -18 17 -30 38 -80 132 -228 284 -368 377 -158 105 -313 167 -497 199 -111
            20 -330 19 -439 0z m810 -1023 c47 -22 89 -64 104 -104 7 -19 11 -235 11 -650
            l0 -621 208 206 c223 221 243 235 328 225 96 -10 164 -89 164 -190 0 -44 -5
            -61 -29 -93 -16 -21 -199 -208 -407 -416 -350 -347 -382 -377 -420 -383 -98
            -15 -95 -18 -512 398 -211 210 -390 395 -399 412 -23 43 -21 123 3 170 27 52
            89 96 147 102 86 10 106 -3 330 -225 l207 -206 0 621 c0 415 4 631 11 650 24
            64 109 123 179 123 19 0 53 -9 75 -19z"
        />
      </g>
    </svg>
  );
};

export default CsvIcon;