import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { standardsContext } from "../../App";
import "./stateselector.css";

function StateSelector() {
  const { subject, grade, state, contentType} = useContext(standardsContext);

  return (
    <DropdownButton id="dropdown-basic-button-state" title={"State: " + state}>
      <Dropdown.Item
        href={"?state=AL&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Alabama
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=AK&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Alaska
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=AZ&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Arizona
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=AR&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Arkansas
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=CA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        California
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=CO&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Colorado
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=CT&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Connecticut
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=DE&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Delaware
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=FL&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Florida
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=GA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Georgia
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=HI&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Hawaii
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=ID&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Idaho
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=IL&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Illinois
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=IN&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Indiana
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=IA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Iowa
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=KS&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Kansas
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=KY&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Kentucky
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=LA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Louisiana
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=ME&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Maine
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MD&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Maryland
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Massachusetts
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MI&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Michigan
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MN&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Minnesota
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MS&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Mississippi
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MO&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Missouri
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=MT&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Montana
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NE&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Nebraska
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NV&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Nevada
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NH&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        New Hampshire
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NJ&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        New Jersey
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NM&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        New Mexico
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NY&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        New York
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=NC&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        North Carolina
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=ND&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        North Dakota
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=OH&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Ohio
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=OK&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Oklahoma
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=OR&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Oregon
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=PA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Pennsylvania
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=RI&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Rhode Island
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=SC&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        South Carolina
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=SD&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        South Dakota
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=TN&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Tennessee
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=TX&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Texas
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=UT&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Utah
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=VT&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Vermont
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=VA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Virginia
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=WA&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Washington
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=WV&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        West Virginia
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=WI&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Wisconsin
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=WY&gradeband=" + grade + "&subject=" + subject + "&contenttype=" + contentType}
      >
        Wyoming
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default StateSelector;
