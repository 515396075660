import React from "react";
import "./tablerow.css";

export interface TableRowProps {
  id: string;
  name: string;
  description: string;
  standardCodes: any[];
  placementGrade: number[];
}

const matchLeadingZero = /^0/;
const replaceLeadingKindergarten = "K";
const emptyString = "";
const spaceCharWithComma = ", ";

const TableRow = ({ data = [] }: { data: TableRowProps[] }) => {
  return (
    <tbody data-testid="tbody">
      {data.map((row: TableRowProps) => {
        let grades: number[] = [];

        if (
          Array.isArray(row.placementGrade) &&
          row.placementGrade.length > 0
        ) {
          grades = row.placementGrade;
        }
        const displayPlacementGrade = grades
          .join(spaceCharWithComma)
          .replace(matchLeadingZero, replaceLeadingKindergarten);

        return (
          <tr key={row.id}>
            <td style={{ textAlign: "center" }}>{displayPlacementGrade}</td>
            <td style={{ whiteSpace: "break-spaces" }}>
              {row.standardCodes.length > 1
                ? row.standardCodes.map((item) => {
                    return item.code + "\r\n";
                  })
                : row.standardCodes.length === 1
                ? row.standardCodes[0].code
                : emptyString}
            </td>

            <td>{row.name ? row.name : emptyString}</td>
            <td>{row.description ? row.description : emptyString}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableRow;
