import React, { useContext } from "react";
import jsPDF from "jspdf";
import { Tooltip as ReactTooltip } from "react-tooltip";
import IlLogoPrint from "../images/illogoprint";
import IlFooterPrint from "../images/ilfooterprint";
import PrintIcon from "../images/printicon";
import { standardsContext } from "../../App";
import "./pdf.css";
import "react-tooltip/dist/react-tooltip.css";
import "jspdf-autotable";

function Pdf() {
  const { subject, grade, state } = useContext(standardsContext);
  const handleClick = () => {
    const pdf = new jsPDF("l", "mm", "a3");
    // const pdf = new jsPDF("p");
    pdf.autoTable({
      html: "#StandardsGuideTable",
      theme: "grid",
      margin: { top: 25, bottom: 18 },
      headStyles: {
        lineColor: 0,
        textColor: 0,
        fontStyle: "normal",
        fillColor: 255,
        halign: "center",
        valign: "middle",
        lineWidth: 0.2,
      },
      bodyStyles: {
        lineColor: 0,
        lineWidth: 0.2,
      },
    });

    // Add page numbers to footer
    const pageCount = pdf.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      pdf.setFont("helvetica", "italic");
      pdf.setFontSize(10);
      pdf.setPage(i);
      pdf.text(
        "Page " + String(i) + " of " + String(pageCount),
        pdf.internal.pageSize.width / 2,
        287,
        {
          align: "center",
        }
      );
      pdf.text(
        "Grade: " + grade + " Subject: " + subject + " State: " + state,
        14,
        23,
        {
          align: "left",
        }
      );
      pdf.setFontSize(13);
      pdf.setFont("helvetica", "bold");
      pdf.text("Standards Guide", 14, 18);
      pdf.addImage(IlLogoPrint(), "JPEG", 375, 10, 30, 10);
      pdf.addImage(IlFooterPrint(), "JPEG", 14, 281, 25, 9);
    }
    pdf.save("StandardsGuide_" + grade + "-" + subject + "-" + state + ".pdf");
  };

  return (
    <>
      <button
        id="pdf-button"
        style={{
          background: "none",
          border: "1.5px solid #150e73",
          borderRadius: "5px",
          height: "40px",
          width: "40px",
        }}
        onClick={handleClick}
      >
        <PrintIcon />
      </button>
      <ReactTooltip
        anchorId="pdf-button"
        place="bottom"
        content="Export PDF"
        style={{
          borderRadius: "6px",
          backgroundColor: "#f4f4f6 ",
          color: "#424242",
          padding: ".4rem",
          opacity: "1",
          border: "1px solid #424242",
        }}
      />
    </>
  );
}

export default Pdf;
