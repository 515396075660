import React from "react";
import IlLogo from "../../components/images/illogo";
import IlFooter from "../../components/images/ilfooter";
import ReportTable from "../../components/reporttable/reporttable";
import "./report.css";
import StateSelector from "../../components/stateselector/stateselector";
import SubjectSelector from "../../components/subjectselector/subjectselector";
import GradeSelector from "../../components/gradeselector/gradeselector";
import ContentTypeSelector from "../../components/contenttypeselector/contenttypeselector";
import Pdf from "../../components/pdf/pdf";
import Csv from "../../components/csv/csv";

const Report = ({ state = "AZ", subject = "Math", grade = "1", contentType = "Instruction"}) => {
  return (
    <section className="report">
      <div className="header">
        <h1
          style={{
            color: "#150e73",
          }}
        >
          Standards Guide
        </h1>
        <IlLogo />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "5px" }}>
            <SubjectSelector />
          </div>
          <div style={{paddingRight: "5px"}}>
            <ContentTypeSelector />
          </div>
          <div style={{ paddingRight: "5px" }}>
            <GradeSelector />
          </div>
          <div>
            <StateSelector />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "5px" }}>
            <Pdf />
          </div>
          <div>
            <Csv />
          </div>
        </div>
      </div>
      <ReportTable state={state} grade={grade} subject={subject} contentType = {contentType}/>
      <div className="footer">
        <IlFooter />
      </div>
    </section>
  );
};

export default Report;
