import React, { useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CsvIcon from "../images/csvicon";
import { standardsContext } from "../../App";
import { saveAs } from "file-saver";
import "react-tooltip/dist/react-tooltip.css";
import "./csv.css";

function Csv() {
  const { subject, grade, state } = useContext(standardsContext);
  const handleClick = () => {
    let csv = [];
    const rows = document.querySelectorAll("table tr");
    for (const row of rows.values()) {
      const cells = row.querySelectorAll("td, th");
      var rowText = Array.from(cells).map((cell) => cell.innerText);

      rowText[0] = '"' + rowText[0] + '"';
      rowText[1] = '"' + rowText[1] + '"';
      rowText[2] = '"' + rowText[2] + '"';
      if (rowText[3].includes('"')) {
        rowText[3] = rowText[3].replace(/"/g, "'");
      }
      rowText[3] = '"' + rowText[3] + '"';

      if (rowText[1].includes("\n")) {
        rowText[1] = rowText[1].replace(/(\r\n|\n|\r)/gm, ", ");
        rowText[1] = rowText[1].substring(0, rowText[1].length - 3) + '"';
      }
      csv.push(rowText.join(","));
    }
    const csvFile = new Blob([csv.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    saveAs(
      csvFile,
      "StandardsGuide_" + grade + "_" + subject + "_" + state + ".csv"
    );
  };

  return (
    <>
      <button
        id="csv-button"
        style={{
          background: "none",
          border: "1.5px solid #150e73",
          borderRadius: "5px",
          height: "40px",
          width: "40px",
        }}
        onClick={handleClick}
      >
        <CsvIcon />
      </button>
      <ReactTooltip
        anchorId="csv-button"
        place="bottom"
        content="Export CSV"
        style={{
          borderRadius: "6px",
          backgroundColor: "#f4f4f6",
          color: "#424242",
          padding: ".4rem",
          opacity: "1",
          border: "1px solid #424242",
        }}
      />
    </>
  );
}

export default Csv;
