import React from "react";
import { hosts, detectEnvironment } from "../../config";

const env = detectEnvironment(new URL(window.location.toString()));

const useFetchData = (state = "AZ", gradeString = "1", subject = "Math", contentType = "Instruction") => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiData, setApiData] = React.useState([]);
  const [serverError, setServerError] = React.useState("");

  // This is a hack to get around the fact that the API only accepts uppercase "SEL" and for the other subjects lowercase.
  subject = (subject === "Purpose" ? "SEL" : subject.toLowerCase());
  contentType = (contentType === "Instruction"? "Tier2" : "Tier1");

  try {
    React.useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
        const response = await fetch(
          `https://${hosts[env]["standards-alignment-api.mptools.edgenuity.com"]}/api/Lesson/LessonLibrary/${subject}?Offset=0&Limit=99999&State=${state}${gradeString}&SortDirection=asc&Type=${contentType}`
        );
        if (response.status !== 204) {
          const receivedData = await response.json();
          setApiData(receivedData.items);
        } else {
          setApiData([]);
        }
        setIsLoading(false);
      };
      fetchData();
    }, [state, gradeString, subject, contentType]);
  } catch (error) {
    console.error(error);
    setServerError("Error fetching data");
  }

  return { isLoading, apiData, serverError };
};

export default useFetchData;
