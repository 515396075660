import React, { useEffect, useState, createContext } from "react";

import { useLocation } from "react-router-dom";

import Main from "./components/main/main";

const defaultState = {
  subject: "",
  grade: "",
  state: "",
  contentType: ""
};

const App = () => {
  const location = useLocation();
  let pathUrl = location.search;
  const searchParams = new URLSearchParams(pathUrl);
  const inputState = searchParams.get("state") || "";
  const inputSubject = searchParams.get("subject") || "";
  const inputGrade = searchParams.get("gradeband") || "";
  const inputContentType = searchParams.get("contenttype") || "";
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");
  const [state, setState] = useState("");
  const [contentType, setContentType] = useState("");
  useEffect(() => {
    if (location.search) {
      setSubject(inputSubject);
      setGrade(inputGrade);
      setState(inputState);
      setContentType(inputContentType);
    } else {
      setSubject("Math");
      setGrade("1");
      setState("AZ");
      setContentType("Instruction");
    }
  }, [location.search, inputGrade, inputState, inputSubject, inputContentType]);
  return (
    <standardsContext.Provider value={{ subject, grade, state, contentType }}>
      <Main />
    </standardsContext.Provider>
  );
};
export const standardsContext = createContext(defaultState);
export default App;
