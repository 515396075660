import React from 'react';

const IlLogo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="150pt"
      height="66.3139329806pt"
      viewBox="0 0 567.000000 188.000000"
      preserveAspectRatio="xMidYMid meet"
      alt="Imagine Learning Logo"
      id='ImagineLearningLogo'
    >
      <g
        transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill="#150e73"
          d="M125 1856 c-59 -27 -77 -46 -104 -106 -20 -44 -21 -62 -21 -810 0
   -748 1 -766 21 -810 27 -60 45 -79 104 -106 l50 -24 765 0 765 0 50 24 c59 27
   77 46 104 106 20 44 21 62 21 810 l0 765 -24 50 c-27 59 -46 77 -106 104 -44
   20 -62 21 -810 21 l-765 0 -50 -24z m1176 -397 c27 -53 105 -131 158 -158 22
   -12 41 -26 41 -31 0 -5 -24 -25 -53 -43 -58 -37 -129 -112 -149 -158 -18 -40
   -43 -37 -65 9 -21 45 -105 130 -156 157 -45 24 -47 41 -5 63 50 27 118 93 155
   150 18 28 38 52 43 52 5 0 19 -19 31 -41z m-507 -416 c19 -38 53 -89 76 -116
   48 -54 146 -127 203 -150 20 -9 37 -22 37 -30 0 -8 -30 -29 -67 -47 -91 -44
   -207 -161 -253 -253 -18 -37 -38 -66 -44 -64 -6 2 -28 35 -49 73 -50 91 -151
   192 -240 241 -39 21 -72 43 -74 49 -2 6 27 26 64 44 90 44 206 159 254 252 18
   38 40 68 46 68 7 0 28 -30 47 -67z"
        />
        <path
          d="M3920 1612 c-17 -14 -21 -25 -17 -44 9 -34 43 -42 68 -17 24 24 22
   47 -7 66 -19 12 -24 11 -44 -5z"
        />
        <path
          d="M2461 1601 c-21 -39 15 -76 57 -60 19 7 23 15 20 41 -3 29 -7 33 -35
   36 -23 2 -34 -2 -42 -17z"
        />
        <path
          d="M2713 1471 c-27 -17 -33 -18 -33 -6 0 10 -11 15 -35 15 l-35 0 0
   -150 0 -150 35 0 35 0 0 103 c0 116 10 137 67 137 52 0 63 -24 63 -138 l0
   -102 35 0 35 0 0 105 c0 100 1 105 24 120 14 9 39 15 58 13 46 -4 58 -34 58
   -145 l0 -93 36 0 36 0 -3 119 c-3 102 -7 122 -24 141 -49 54 -112 64 -167 26
   -36 -24 -36 -24 -55 -4 -30 29 -91 33 -130 9z"
        />
        <path
          d="M3232 1473 c-106 -52 -112 -219 -10 -284 40 -25 117 -22 148 6 18 16
   20 16 20 2 0 -13 9 -17 35 -17 l35 0 0 150 0 150 -35 0 c-23 0 -35 -5 -35 -14
   0 -11 -8 -10 -37 5 -45 23 -78 24 -121 2z m132 -74 c22 -18 26 -28 26 -74 0
   -62 -21 -85 -78 -85 -46 0 -71 16 -88 56 -12 29 -12 39 0 68 8 19 22 39 33 45
   29 17 78 13 107 -10z"
        />
        <path
          d="M3596 1468 c-91 -53 -102 -184 -23 -257 35 -31 111 -40 155 -17 l32
   17 0 -30 c0 -36 -40 -71 -82 -71 -16 0 -45 7 -65 15 -33 14 -38 14 -52 0 -50
   -50 105 -98 194 -61 66 28 75 59 75 249 l0 167 -35 0 c-26 0 -35 -4 -35 -17 0
   -15 -2 -14 -20 2 -31 28 -98 30 -144 3z m142 -64 c18 -13 22 -25 22 -72 0 -54
   -2 -59 -31 -76 -90 -53 -188 66 -114 139 29 29 88 34 123 9z"
        />
        <path
          d="M4153 1470 c-27 -16 -33 -17 -33 -5 0 10 -11 15 -35 15 l-35 0 0
   -150 0 -150 35 0 35 0 0 99 c0 96 1 100 26 120 35 28 69 26 99 -4 23 -22 25
   -32 25 -120 l0 -95 35 0 35 0 0 100 c0 118 -14 161 -62 190 -42 25 -84 25
   -125 0z"
        />
        <path
          d="M4500 1479 c-87 -35 -126 -145 -81 -229 46 -87 201 -108 261 -36 12
   16 11 20 -10 36 -22 17 -24 17 -57 -3 -42 -24 -100 -18 -125 14 -37 44 -27 49
   97 49 l115 0 0 39 c0 96 -108 166 -200 130z m105 -74 c40 -39 32 -45 -55 -45
   -49 0 -80 4 -80 10 0 23 50 60 80 60 19 0 40 -9 55 -25z"
        />
        <path d="M2460 1330 l0 -150 35 0 35 0 0 150 0 150 -35 0 -35 0 0 -150z" />
        <path d="M3910 1330 l0 -150 35 0 35 0 0 150 0 150 -35 0 -35 0 0 -150z" />
        <path
          d="M5230 585 l0 -325 50 0 50 0 0 148 c0 141 1 150 23 169 70 64 154 57
   192 -17 11 -22 15 -64 15 -165 l0 -135 50 0 50 0 0 148 c0 163 -8 206 -47 253
   -34 40 -79 59 -140 59 -46 0 -107 -26 -125 -54 -4 -6 -8 46 -8 117 l0 127 -55
   0 -55 0 0 -325z"
        />
        <path
          d="M4920 775 l0 -65 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -117 c0 -137
   17 -189 72 -223 40 -24 109 -35 135 -22 15 8 18 19 15 51 -4 39 -6 41 -37 41
   -18 0 -43 9 -56 19 -23 18 -24 25 -24 133 l0 113 58 3 57 3 0 44 0 45 -60 0
   -60 0 0 65 0 65 -50 0 -50 0 0 -65z"
        />
        <path
          d="M2645 704 c-16 -8 -38 -23 -47 -31 -17 -15 -18 -14 -18 10 0 26 -3
   27 -50 27 l-50 0 0 -225 0 -225 50 0 50 0 0 154 c0 152 0 153 26 179 49 49
   126 44 161 -10 15 -22 19 -53 21 -175 l4 -148 49 0 49 0 2 162 3 162 34 23
   c39 26 70 29 110 9 44 -23 51 -53 51 -211 l0 -145 51 0 50 0 -3 178 c-3 167
   -4 179 -26 210 -61 85 -202 97 -280 22 l-24 -22 -14 21 c-35 49 -137 67 -199
   35z"
        />
        <path
          d="M3933 699 c-24 -12 -43 -25 -43 -30 0 -5 -4 -9 -10 -9 -5 0 -10 11
   -10 25 0 23 -3 25 -50 25 l-50 0 0 -305 0 -305 50 0 50 0 0 105 c0 58 3 105 6
   105 3 0 19 -11 35 -24 85 -72 236 -29 299 84 31 57 34 165 6 220 -60 115 -179
   161 -283 109z m138 -95 c84 -42 86 -183 4 -234 -39 -24 -104 -26 -145 -5 -47
   24 -60 52 -60 125 0 60 3 69 29 94 47 45 109 52 172 20z"
        />
        <path
          d="M4423 699 c-82 -40 -123 -111 -123 -212 0 -185 182 -299 324 -202
   l36 24 0 -24 c0 -23 3 -25 50 -25 l50 0 0 225 0 225 -50 0 c-48 0 -50 -1 -50
   -27 l0 -26 -22 21 c-51 47 -144 56 -215 21z m160 -83 c68 -25 77 -39 77 -128
   0 -65 -3 -81 -20 -98 -44 -44 -124 -53 -177 -20 -96 58 -76 218 32 249 41 12
   44 12 88 -3z"
        />
        <path
          d="M3240 706 c0 -2 40 -98 88 -213 l88 -209 -38 -83 c-21 -46 -38 -87
   -38 -92 0 -5 24 -9 53 -9 l52 0 120 287 c66 158 123 295 127 305 7 17 3 19
   -49 16 l-57 -3 -56 -142 c-30 -78 -58 -144 -61 -147 -3 -4 -32 61 -64 144
   l-58 150 -53 0 c-30 0 -54 -2 -54 -4z"
        />
      </g>
    </svg>
  );
};

export default IlLogo;
