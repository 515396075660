import React from "react";

const PrintIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="18pt"
      height="18pt"
      viewBox="0 0 1200.000000 1200.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"
        fill="#150e73"
        stroke="none"
      >
        <path
          d="M2605 11199 c-201 -34 -377 -163 -465 -341 -74 -152 -70 -12 -70
            -2080 l0 -1856 -317 -5 c-275 -4 -330 -7 -403 -25 -263 -63 -477 -194 -673
            -412 -165 -184 -274 -413 -306 -644 -8 -52 -11 -559 -11 -1557 0 -1238 2
            -1485 14 -1512 17 -39 81 -102 119 -116 19 -8 282 -11 802 -11 l774 0 3 -602
            c3 -592 4 -604 25 -668 33 -95 89 -185 164 -259 108 -108 217 -162 367 -182
            51 -6 1191 -9 3352 -7 3105 3 3278 4 3334 21 104 31 196 86 277 167 82 81 118
            135 158 239 l26 66 5 610 5 610 785 5 c719 5 788 6 817 22 43 24 89 82 102
            132 9 30 11 434 8 1531 -4 1635 1 1525 -63 1720 -115 352 -430 676 -776 800
            -173 62 -222 68 -563 74 l-310 6 -5 955 -6 955 -26 100 c-58 219 -156 443
            -269 615 -54 83 -137 171 -658 691 -661 661 -667 666 -914 784 -150 72 -296
            124 -442 157 l-100 22 -2355 2 c-1295 1 -2377 -2 -2405 -7z m4608 -1441 c4
            -655 2 -633 71 -773 67 -135 207 -258 356 -313 l75 -27 608 -3 607 -3 0 -1284
            0 -1285 -3000 0 -3000 0 0 2140 0 2140 2139 0 2140 0 4 -592z m3143 -3720
            c162 -59 271 -203 281 -372 10 -158 -57 -293 -193 -386 -115 -80 -279 -92
            -407 -31 -216 103 -311 363 -210 571 34 71 132 169 203 203 97 47 223 53 326
            15z m-1426 -3403 l0 -855 -3000 0 -3000 0 0 855 0 855 3000 0 3000 0 0 -855z"
        />
      </g>
    </svg>
  );
};

export default PrintIcon;
