import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { standardsContext } from "../../App";
import "./contenttypeselector.css";

function ContentTypeSelector() {
  const { subject, grade, state, contentType } = useContext(standardsContext);

  return (
    <DropdownButton disabled = {subject !== "Math"} id="dropdown-basic-button-subject" title = {"Content Type: " + contentType}>
      <Dropdown.Item
        href={"?state=" + state + "&gradeband=" + grade + "&subject=" + subject + "&contenttype=Instruction"}
      >
        Instruction
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=" + state + "&gradeband=" + grade + "&subject=" + subject + "&contenttype=Practice"}
      >
        Practice
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default ContentTypeSelector;
