import React from 'react';

const IlFooter = () => {
  return (
    <svg
      width="133.477891156"
      height="50"
      viewBox="0 0 1427 588"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M527.871 107.838C527.839 112.167 526.104 116.31 523.041 119.372C519.978 122.433 515.833 124.167 511.501 124.199C507.324 124.018 503.378 122.233 500.487 119.216C497.595 116.199 495.981 112.182 495.981 108.003C495.981 103.825 497.595 99.8082 500.487 96.7907C503.378 93.7732 507.324 91.9882 511.501 91.8081C515.772 91.8527 519.86 93.5457 522.911 96.5331C525.961 99.5205 527.739 103.571 527.871 107.838ZM524.042 254.333H498.779V143.332H524.042V254.333Z"
        fill="#00007C"
      />
      <path
        d="M725.001 186.449V254.333H699.406V191.209C699.406 174.637 690.091 164.423 675.108 164.423C669.996 164.69 665.027 166.202 660.635 168.828C656.242 171.454 652.558 175.113 649.905 179.488L650.568 183.345V254.333H625.245V191.209C625.245 174.637 615.477 164.423 601.369 164.423C596.153 164.419 591.038 165.862 586.594 168.591C582.15 171.32 578.55 175.228 576.196 179.88V254.333H550.933V143.332H576.196V157.824C580.093 152.541 585.194 148.262 591.077 145.343C596.96 142.424 603.454 140.95 610.021 141.042C625.245 141.042 636.369 146.706 643.393 158.969C647.814 153.544 653.352 149.134 659.631 146.04C665.91 142.946 672.783 141.241 679.781 141.042C706.37 141.042 725.001 159.12 725.001 186.449Z"
        fill="#00007C"
      />
      <path
        d="M859.968 143.331V254.333H834.735V239.268C828.706 249.271 814.054 256.532 799.312 256.532C769.347 256.532 745.922 231.072 745.922 198.621C745.922 166.171 769.346 140.951 799.373 140.951C814.145 140.951 828.917 148.213 834.825 158.216V143.151L859.968 143.331ZM834.735 215.977V181.899C831.571 176.753 827.137 172.506 821.857 169.567C816.578 166.628 810.631 165.095 804.588 165.116C795.888 164.914 787.462 168.169 781.161 174.168C774.859 180.167 771.196 188.419 770.974 197.115V198.862C770.788 207.612 774.072 216.081 780.109 222.42C786.147 228.759 794.448 232.454 803.201 232.699H804.588C810.599 232.68 816.51 231.153 821.777 228.257C827.044 225.362 831.5 221.191 834.735 216.127"
        fill="#00007C"
      />
      <path
        d="M995.116 143.091V250.235C995.116 280.908 971.722 300.644 940.159 300.644C920.835 300.644 899.943 293.593 889.723 282.264L902.445 264.788C911.308 273.406 924.935 277.955 939.254 277.955C956.287 277.955 969.914 266.385 969.914 249.783V237.339C963.884 246.861 949.233 254.122 934.461 254.122C904.495 254.122 881.101 229.174 881.101 197.476C881.101 165.779 904.405 141.042 934.371 141.042C949.112 141.042 963.884 148.092 969.793 157.824V143.091H995.116ZM969.914 214.38V180.784C966.667 175.76 962.199 171.64 956.927 168.811C951.655 165.981 945.751 164.534 939.767 164.604C931.408 164.986 923.502 168.513 917.636 174.477C911.769 180.441 908.375 188.401 908.135 196.761C907.895 205.121 910.826 213.263 916.34 219.554C921.854 225.844 929.544 229.819 937.868 230.68H939.767C945.74 230.676 951.62 229.193 956.88 226.365C962.141 223.536 966.618 219.45 969.914 214.47"
        fill="#00007C"
      />
      <path
        d="M1051.01 107.838C1050.99 112.172 1049.26 116.324 1046.2 119.389C1043.13 122.454 1038.98 124.183 1034.64 124.199C1030.46 124.018 1026.52 122.233 1023.62 119.216C1020.73 116.199 1019.12 112.182 1019.12 108.003C1019.12 103.825 1020.73 99.8082 1023.62 96.7907C1026.52 93.7732 1030.46 91.9882 1034.64 91.8081C1038.91 91.8527 1043 93.5457 1046.05 96.5331C1049.1 99.5205 1050.88 103.571 1051.01 107.838ZM1047.15 143.271V254.333H1021.98V143.332L1047.15 143.271Z"
        fill="#00007C"
      />
      <path
        d="M1178.74 190.998V254.273H1153.33V194.976C1153.33 177.078 1142.87 166.171 1127.19 166.171C1121.6 166.142 1116.1 167.544 1111.21 170.245C1106.31 172.946 1102.19 176.854 1099.24 181.597V254.333H1074.04V143.332H1099.24V158.397C1103.06 152.936 1108.16 148.502 1114.11 145.49C1120.05 142.478 1126.65 140.981 1133.31 141.132C1161.04 141.132 1178.74 160.446 1178.74 191.089"
        fill="#00007C"
      />
      <path
        d="M1309.31 204.889H1224.89C1227.58 222.967 1239.97 233.724 1257.82 233.724C1270.33 233.724 1281.66 228.33 1286.91 219.653L1307.11 232.579C1299.03 247.644 1280.3 256.683 1257.36 256.683C1249.83 256.739 1242.35 255.307 1235.37 252.47C1228.39 249.633 1222.03 245.446 1216.67 240.15C1211.31 234.854 1207.05 228.553 1204.13 221.608C1201.21 214.662 1199.69 207.209 1199.66 199.676V198.32C1199.59 190.875 1200.98 183.487 1203.76 176.58C1206.54 169.673 1210.66 163.382 1215.88 158.065C1221.09 152.749 1227.3 148.512 1234.16 145.596C1241.01 142.68 1248.38 141.143 1255.83 141.072H1256.91C1263.81 140.964 1270.67 142.219 1277.09 144.763C1283.5 147.308 1289.36 151.093 1294.31 155.902C1299.26 160.71 1303.21 166.448 1305.94 172.785C1308.67 179.123 1310.12 185.937 1310.21 192.836V194.132C1310.21 197.786 1309.91 201.435 1309.31 205.039V204.889ZM1283.62 185.725C1282.26 171.895 1270.66 162.133 1255.46 162.133C1240.27 162.133 1229.54 171.172 1225.77 185.725H1283.62Z"
        fill="#00007C"
      />
      <path
        d="M524.012 323.603H498.779V482.513H524.012V323.603Z"
        fill="#00007C"
      />
      <path
        d="M652.799 433.038H568.388C571.101 450.966 583.461 461.873 601.369 461.873C613.819 461.873 625.185 456.48 630.43 447.802L650.659 460.728C642.549 475.793 623.858 484.833 600.886 484.833C593.341 484.904 585.857 483.485 578.862 480.657C571.868 477.829 565.502 473.648 560.129 468.353C554.756 463.059 550.482 456.756 547.554 449.806C544.626 442.856 543.1 435.396 543.065 427.856V426.469C542.981 419.024 544.368 411.636 547.145 404.727C549.922 397.819 554.036 391.525 559.25 386.208C564.464 380.89 570.676 376.652 577.531 373.738C584.386 370.823 591.749 369.288 599.198 369.221H600.313C607.209 369.114 614.057 370.365 620.469 372.904C626.88 375.443 632.727 379.22 637.677 384.019C642.627 388.818 646.582 394.545 649.316 400.872C652.051 407.2 653.511 414.004 653.613 420.895V422.221C653.644 425.833 653.372 429.441 652.799 433.008V433.038ZM627.054 413.724C625.697 399.894 614.121 390.132 598.897 390.132C583.672 390.132 573 399.171 569.202 413.724H627.054Z"
        fill="#00007C"
      />
      <path
        d="M781.134 371.511V482.543H755.931V467.477C749.902 477.451 735.281 484.712 720.509 484.712C690.513 484.712 667.119 459.282 667.119 426.801C667.119 394.32 690.513 369.161 720.509 369.161C735.281 369.161 750.022 376.392 755.931 386.426V371.36L781.134 371.511ZM755.931 444.156V410.108C752.767 404.961 748.333 400.712 743.054 397.768C737.776 394.824 731.829 393.284 725.784 393.296C717.008 393.558 708.679 397.227 702.564 403.524C696.449 409.821 693.029 418.251 693.029 427.027C693.029 435.802 696.449 444.233 702.564 450.53C708.679 456.827 717.008 460.496 725.784 460.758C731.798 460.736 737.711 459.204 742.978 456.303C748.245 453.402 752.699 449.225 755.931 444.156Z"
        fill="#00007C"
      />
      <path
        d="M874.258 370.999L872.66 394.832C868.415 393.388 863.969 392.625 859.486 392.572C851.075 392.572 839.046 399.382 833.137 411.645V482.482H807.935V371.511H833.167V386.576C836.022 381.508 840.116 377.246 845.067 374.19C850.017 371.134 855.663 369.383 861.475 369.101C865.814 368.955 870.147 369.546 874.288 370.848"
        fill="#00007C"
      />
      <path
        d="M997.166 419.178V482.452H971.752V423.095C971.752 405.016 961.292 394.23 945.615 394.23C940.014 394.21 934.503 395.63 929.609 398.353C924.715 401.075 920.604 405.009 917.669 409.777V482.422H892.346V371.511H917.548V386.576C924.362 375.759 937.536 369.312 951.614 369.312C979.349 369.312 997.046 388.595 997.046 419.238"
        fill="#00007C"
      />
      <path
        d="M1052.85 336.108C1052.82 340.434 1051.08 344.574 1048.02 347.631C1044.95 350.687 1040.81 352.415 1036.48 352.438C1032.3 352.258 1028.36 350.473 1025.46 347.456C1022.57 344.438 1020.96 340.421 1020.96 336.243C1020.96 332.065 1022.57 328.048 1025.46 325.03C1028.36 322.013 1032.3 320.228 1036.48 320.048C1040.75 320.093 1044.85 321.79 1047.9 324.784C1050.95 327.777 1052.72 331.836 1052.85 336.108ZM1049.11 482.543H1023.88V371.511H1049.11V482.543Z"
        fill="#00007C"
      />
      <path
        d="M1180.49 419.178V482.452H1155.05V423.095C1155.05 405.016 1144.58 394.23 1128.94 394.23C1123.35 394.201 1117.84 395.607 1112.95 398.313C1108.05 401.02 1103.94 404.936 1100.99 409.687V482.332H1075.79V371.511H1100.99V386.576C1107.8 375.759 1120.98 369.312 1135.06 369.312C1162.76 369.312 1180.49 388.595 1180.49 419.238"
        fill="#00007C"
      />
      <path
        d="M1315.43 371.3V478.415C1315.43 509.088 1292.03 528.853 1260.47 528.853C1241.17 528.853 1220.25 521.803 1210.03 510.444L1222.75 492.968C1231.62 501.615 1245.24 506.135 1259.53 506.135C1276.57 506.135 1290.22 494.565 1290.22 477.993V465.428C1284.19 474.95 1269.57 482.241 1254.8 482.241C1224.65 482.241 1201.38 457.233 1201.38 425.566C1201.38 393.898 1224.8 369.251 1254.8 369.251C1269.57 369.251 1284.31 376.302 1290.22 386.064V371.3H1315.43ZM1290.28 442.559V408.994C1287.07 403.925 1282.61 399.758 1277.34 396.885C1272.06 394.012 1266.14 392.528 1260.14 392.572C1251.86 393.28 1244.15 397.065 1238.54 403.18C1232.92 409.295 1229.8 417.294 1229.8 425.596C1229.8 433.897 1232.92 441.896 1238.54 448.011C1244.15 454.126 1251.86 457.912 1260.14 458.619C1266.1 458.674 1271.99 457.233 1277.26 454.428C1282.52 451.622 1287 447.542 1290.28 442.559Z"
        fill="#00007C"
      />
      <path
        d="M324.772 499.838C317.118 499.842 309.539 498.338 302.467 495.412C295.395 492.487 288.969 488.197 283.557 482.788C278.145 477.379 273.853 470.956 270.926 463.888C267.999 456.82 266.494 449.245 266.498 441.595V142.277C266.498 126.83 272.638 112.016 283.566 101.093C294.494 90.1707 309.317 84.0344 324.772 84.0344C340.227 84.0344 355.049 90.1707 365.978 101.093C376.906 112.016 383.046 126.83 383.046 142.277V441.595C383.046 457.042 376.906 471.856 365.978 482.779C355.049 493.701 340.227 499.838 324.772 499.838Z"
        fill="#00007C"
      />
      <path
        d="M157.788 499.838C150.133 499.842 142.552 498.338 135.479 495.413C128.405 492.488 121.977 488.198 116.563 482.789C111.149 477.381 106.854 470.959 103.923 463.89C100.993 456.822 99.4844 449.246 99.4844 441.595V303.747C99.4844 288.3 105.624 273.486 116.552 262.563C127.481 251.641 142.303 245.505 157.758 245.505C173.213 245.505 188.036 251.641 198.964 262.563C209.892 273.486 216.032 288.3 216.032 303.747V441.595C216.024 457.039 209.882 471.849 198.955 482.77C188.028 493.691 173.211 499.83 157.758 499.838"
        fill="#00007C"
      />
      <path
        d="M216.062 142.277C216.062 153.796 212.645 165.057 206.241 174.635C199.838 184.213 190.737 191.678 180.089 196.086C169.441 200.494 157.724 201.648 146.42 199.4C135.116 197.153 124.732 191.606 116.583 183.461C108.433 175.315 102.883 164.938 100.634 153.64C98.3859 142.342 99.5399 130.631 103.951 119.989C108.361 109.346 115.83 100.25 125.413 93.8501C134.996 87.4503 146.263 84.0344 157.788 84.0344C173.244 84.0344 188.066 90.1707 198.994 101.093C209.923 112.016 216.062 126.83 216.062 142.277Z"
        fill="#00007C"
      />
    </svg>
  );
};

export default IlFooter;
