// This file is used to configure the application at runtime.
// The same built file is deployed to all environments,
// but the configuration has to be different for each environment.

export const hosts = Object.freeze({
  prod: {
    "standards-alignment-api.mptools.edgenuity.com":
      "standards-alignment-api.mptools-prod.edgenuityapp.com",
  },
  staging: {
    "standards-alignment-api.mptools.edgenuity.com":
      "standards-alignment-api.mptools-staging.edgenuityapp.com",
  },
  qa: {
    "standards-alignment-api.mptools.edgenuity.com":
      "standards-alignment-api.mptools-qa.edgenuityapp.com",
  },
  dev: {
    "standards-alignment-api.mptools.edgenuity.com":
      "standards-alignment-api.mptools-dev.edgenuityapp.com",
  },
  local: {
    "standards-alignment-api.mptools.edgenuity.com": "standards-alignment-api.mptools-prod.edgenuityapp.com",
  },
});

// Given the URL in the browser's address bar, determine the environment
export const detectEnvironment = (url: URL) => {
  if (url.host.includes("localhost")) {
    return "local";
  } else if (url.host.includes("dev")) {
    return "dev";
  } else if (url.host.includes("qa")) {
    return "qa";
  } else if (url.host.includes("staging")) {
    return "staging";
  } else {
    return "prod";
  }
};
