import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { standardsContext } from "../../App";
import "./gradeselector.css";

function GradeSelector() {
  const { subject, grade, state, contentType } = useContext(standardsContext);
    const gradesSelection = [
      "K-12",
      "K-5",
      "6-12",
      "K",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
  const gradesSelectionScienceOrPurpose = ["K-5", "K", "1", "2", "3", "4", "5"];
  const gradesSelectionMathAndPractice = ["K-2", "3-6", "K-6", "K", "1", "2", "3", "4", "5", "6"];
  let toggleSelection = gradesSelection;
  if (
    subject.toLowerCase() === "science" ||
    subject.toLowerCase() === "purpose"
  ) {
    toggleSelection = gradesSelectionScienceOrPurpose;
  }

  if (subject.toLowerCase() === "math" && contentType.toLowerCase() === "practice") {
    toggleSelection = gradesSelectionMathAndPractice;
  }

  return (
    <DropdownButton id="dropdown-basic-button-grade" title={"Grade: " + grade}>
      {toggleSelection.map((gradeLevel) => (
        <Dropdown.Item key={gradeLevel}
          href={"?state=" + state + "&gradeband=" + gradeLevel + "&subject=" + subject + "&contenttype=" + contentType}
        >
          {gradeLevel}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default GradeSelector;
