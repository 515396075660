import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { standardsContext } from "../../App";
import "./subjectselector.css";

function SubjectSelector() {
  const { subject, grade, state, contentType } = useContext(standardsContext);

  return (
    <DropdownButton id="dropdown-basic-button-subject" title={"Subject: " + subject}>
      <Dropdown.Item
        href={"?state=" + state + "&gradeband=" + grade + "&subject=Math&contenttype=" + contentType}
      >
        Math
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=" + state + "&gradeband=" + grade + "&subject=Reading&contenttype=" + contentType}
      >
        Reading
      </Dropdown.Item>
      <Dropdown.Item
        href={"?state=" + state + "&gradeband=" + grade + "&subject=Purpose&contenttype=" + contentType}
      >
        Purpose
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default SubjectSelector;
